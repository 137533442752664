import React from "react"
import Layout from "../components/layout"
import {
  Center,
  Flex,
  Heading,
  ListItem,
  Spacer,
  Text,
  UnorderedList,
  Box,
  Image,
} from "@chakra-ui/react"
import anne from "../media/pflanze.svg"
import Elink from "../components/elink"

export default function Home() {
  return (
    <Layout title="Yoga" slug="yoga/">
      <Box textAlign="center">
        <Text fontSize="lg" as="i">
        „Du musst das Aroma einer Pose schmecken!“
        </Text>
        <Text fontSize="sm">B.K.S. Iyengar</Text>
      </Box>
      <br />
      <Text>
        Historisch betrachtet gibt es 5 Hauptwege die Yoga hervorgebracht hat.
        Hatha Yoga ist der jüngste Weg, der ursprünglich den Körper auf den Raja
        Yoga Weg, z.B. auf langes Sitzen im Meditationssitz, vorbereiten soll.
      </Text>

      <Heading as="h2" size="md" mt={12} mb={3}>
        Hatha Yoga
      </Heading>
      <br />
      <Text>Meine Yogastunden beinhalten einen körperbetonten, dynamischen Hatha Yoga Stil nach 
              <Elink
                title="Helen Meyer - Yogibar Berlin"
                href="https://www.helenmeyer.de/"
              > Helen Meyer, 
              </Elink> der das Ziel hat, ein Gleichgewicht zwischen den physiologischen Körperfunktionen zu erhalten (Homöostase).
              <br /> 
              <br /> 
              Der dynamische Hatha Yoga ist geprägt durch eine präzise, körperliche Ausrichtung und beinhaltet den Einsatz vieler 
              Hilfsmittel (z.B. Decken, Blöcke, Bolster und Gurte).
      </Text>
      <br />
      <Flex mt={12} display={["block", "flex"]}>
        <Box mb={6}>
          <Text mb={3}>Aufbau:</Text>
          <UnorderedList mb={3}>
            <ListItem>Körperwahrnehmungsübungen</ListItem>
            <ListItem>Mobilisations- und Kräftigungsübungen</ListItem>
            <ListItem>Atembeobachtungen, -übungen (pranayama)</ListItem>
            <ListItem>Yogahaltungen (asana) ggfs. mit Hilfsmitteln</ListItem>
            <ListItem>Atemmeditation, Metta Meditation</ListItem>
            <ListItem>Tiefenentspannung (shavasana)</ListItem>
          </UnorderedList>
        </Box>
        <Spacer />
        <Center>
          <Box>
            <Image src={anne} alt="Illustration von einer Pflanze" />
          </Box>
        </Center>
        <Spacer />
      </Flex>
      <Heading as="h2" size="md" mt={12} mb={3}>
        Relax and Renew® Restorative Yoga 
      </Heading>
      <br />
      <Text mb={3}>
        Relax and Renew® Restorative Yoga nach
              <Elink
                title="Judith Hanson Lasater, Ph.D. Physical Therapist"
                href="https://www.judithhansonlasater.com/"
              > Judith Hanson Lasater, PhD, PT 
              </Elink> ist ein sehr regenerativer Yogastil. Es wird keinerlei Muskelkraft 
              aufgewendet und ein myofaszialer Dehnungsreiz, wie beim Hatha- oder 
              Yin Yoga, wird nicht forciert. Im Fokus steht die Stimulation des 
              parasympathischen Nervensystems durch den Rückzug der Sinne.
      </Text>
      <Text mb={3}>
        Die Übungen werden meistens in Rücken- , Bauch- oder Seitenlage
        ausgeführt und von sehr vielen Hilfsmitteln unterstützt. Es folgt ein
        meditativ-entspannter Zustand, bei dem so wenig äußere Sinnesreize wie
        möglich gegeben werden. Eine Haltung wird jeweils nach ca. 15-20 Minuten
        sanft aufgelöst.
      </Text>
      <Text mb={3}>
        Restorative Yoga soll zur Förderung von Entspannung, vegetativ wirksamer
        Erholung und Regeneration beitragen und damit den unter Stress
        auftretenden, körperlichen Reaktionen präventiv entgegenwirken.
      </Text>
    </Layout>
  )
}
